import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../smartDongles/views/components";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {DataTableContainer} from "../../../rentLog/utils";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import DataTable from "../../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {appEventActions} from "../../../appEvents/actions";
import {actions} from "../../actions";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {Button, Spinner, Badge} from "react-bootstrap";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import {toBengaliNumber} from "bengali-number";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {Form} from "react-bootstrap";
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import { parseParams} from "../../../../utils/utilityFunctions";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {makeStyles} from "@material-ui/styles";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import FwCompatibilities from "./FwCompatibilities"

const NewFirmwareList = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    const useStylesIconButton = makeStyles({
        root: {
            marginTop: '0',
            paddingTop: '4px',
        },
    })
    const classes = useStylesIconButton()
    const subHeader = useSubheader()

    // Filter related
    const [showFilterForm, setShowFilterForm] = useState(true)
    const [filterApplied, setFilterApplied] = useState(false)
    const [firmwareStatus, setFirmwareStatus] = useState("")
    const firmwareTypes = [
        { value: "firmware", label: "Operation Firmware" },
        { value: "bootloader", label: "Bootloader Firmware" },
        { value: "test_firmware", label: "Test Firmware" },
        { value: "initial_firmware", label: "Initial Firmware" }
    ]

    // Permission related
    const [deviceServiceName, setDeviceServiceName] = useState(getServiceName('deviceService'));
    const [firmwareFeatureName, setFirmwareFeatureName] = useState(getFeatureName('firmwareFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));

    // Firmware list related
    const [firmwares, setFirmwares] = useState([{
        'version': '1.4.7',
        'hardware_version_list': ["1.8.8.2", "1.8.8", "1.8.7"],
        'released_on': 'Oct 16,2022 10:00am',
        'uploaded_by': 'Rayed',
        'status': 'active',
        'firmware_type': 'firmware',
        'app_version_list': ["2.8", "2.7"]
    }, {
        'version': '1.4.6',
        'hardware_version_list': ["1.6", "1.8.6", "1.8.5", "1.8.4"],
        'released_on': 'Oct 16,2022 10:00am',
        'uploaded_by': 'Turja',
        'status': 'active',
        'firmware_type': 'bootloader',
        'app_version_list': ["2.8", "2.7", "2.6"]
    }, {
        'version': '1.4.5',
        'hardware_version_list': ["1.8.8.2", "1.7.0"],
        'released_on': 'Oct 16,2022 10:00am',
        'uploaded_by': 'Mim',
        'status': 'inactive',
        'firmware_type': 'test_firmware',
        'app_version_list': ["2.8"]
    }, {
        'version': '1.4.4',
        'hardware_version_list': ["1.7.3", "1.7.2.0", "1.7.0"],
        'released_on': 'Oct 16,2022 10:00am',
        'uploaded_by': 'Morsed',
        'status': 'inactive',
        'firmware_type': 'initial_firmware',
        'app_version_list': ["2.7"]
    }, {
        'version': '1.4.3',
        'hardware_version_list': ["1.8.6", "1.8.5", "1.8.4", "1.8.3", "1.7.4", "1.7.3", "1.7.2.0", "1.7.0"],
        'released_on': 'Oct 16,2022 10:00am',
        'uploaded_by': 'Rayed',
        'status': 'inactive',
        'firmware_type': 'firmware',
        'app_version_list': ["2.6"]
    }])
    const [showCompatibilityDetailsModal, setShowCompatibilityDetailsModal] = useState(false);
    const [fwTitleForModal, setFwTitleForModal] = useState('');
    const [hwVersionsForModal, setHwVersionsForModal] = useState([]);
    const firmwareListColumns = [
        {
            field: 'version',
            title: 'Version',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.version}</strong></h6>
                </>
            }
        },
        {
            field: 'hardware_version_list',
            title: 'Compatibility',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=> {
                return  <>
                    <a onClick={() => {showCompatibilityDetails(rowData)}}>View</a>
                </>
            }
        },
        {
            field: 'released_on',
            title: 'Released On',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    {rowData.released_on}
                </>
            }
        },
        {
            field: 'uploaded_by',
            title: 'Uploaded By',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return <>{rowData.uploaded_by}</>
            }
        },
        {
            field: 'status',
            title: 'Status',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                const statusMapping = {
                    "active": { "backgroundColor": "#0b6e4f", "text": "Active" },
                    "inactive": { "backgroundColor": "#8C8C9B", "text": "Inactive" }
                }

                return <>
                    <Badge style={{"backgroundColor":statusMapping[rowData.status]["backgroundColor"],
                        "color":"#ffffff"}}>
                        {statusMapping[rowData.status]["text"]}
                    </Badge>
                </>
            }
        }
    ]

    const {
        GET_FIRMWARE_LIST,
        RETURN_TO_INITIAL
    } = props

    useEffect(()=>{
        setAuthorization(props.authorization)
        setLanguage(props.language)
        if (checkAuthorization(props.authorization, deviceServiceName, firmwareFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant={"warning"} size={"sm"}
                            onClick={goToCreateFirmwarePage}>
                        <i className="fa fa-plus"/>{props.language === 'EN'? 'Create Firmware':'ফার্মওয়্যার তৈরি করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    // ----- Begin: Button action related ---- //
    const goToCreateFirmwarePage = () => {
        props.history.push({
            pathname: '/ota/new-firmwares-list/new',
            purpose: 'create'
        })
    }

    const goToAssignDonglePage = (data) => {
        props.history.push({
            pathname: '/ota/new-firmwares-list/assign-dongles',
            fwData: data
        })
    }

    const goToEditFirmwarePage = (data) => {
        props.history.push({
            pathname: '/ota/new-firmwares-list/edit',
            firmwareData: data,
            purpose: 'update'
        })
    }
    // ----- End: Button action related ---- //

    // ----- Begin: Firmware list related ---- //
    const showCompatibilityDetails = (data) => {
        setFwTitleForModal(data.version)
        setShowCompatibilityDetailsModal(true)
    }

    const hideCompatibilityDetailsModal = () => {
        setShowCompatibilityDetailsModal(false)
    }

    const renderCompatibilityDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideCompatibilityDetailsModal}
                modal={showCompatibilityDetailsModal}
                title={<h3>Compatible dongles for FW <strong style={{color: "#F18D00"}}>{fwTitleForModal}</strong></h3>}>
                <FwCompatibilities/>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Button variant={"outline-dark"} size={"sm"} onClick={hideCompatibilityDetailsModal}
                                className={'float-right'}>
                            Close
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }
    // ----- End: Firmware list related ---- //

    // ----- Begin: Data export related ---- //
    const exportData = () => {

    }
    // ----- End: Data export related ---- //


    // ----- Begin: Filtering process related ---- //
    const filterToggle = () => {
        setShowFilterForm(!showFilterForm)
    }

    const handleFirmwareStatusSelectionChange = (event) => {
        setFirmwareStatus(event.target.value)
    }

    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(filterTheList)}>
                <div className={"row g-3"}>
                    <div className={"col-md-3"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Firmware Type</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"firmware_type"}
                                rules={{}}
                                render={({ onChange, onBlur, value, name, ref },
                                         { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Type'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={firmwareTypes}
                                        isSearchable={true}
                                        styles={customStylesForSingleSelectField}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                    <div className={"col-md-3"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Firmware Status</Form.Label>
                            </div>
                            <div className="form-check form-check-inline mt-2">
                                <SolRadioInput
                                    {...register("firmware_status")}
                                    id="active"
                                    value={"active"}
                                    handleChange={(event) => handleFirmwareStatusSelectionChange(event)}
                                    checked={firmwareStatus === "active"}
                                    htmlFor="active"
                                    labelName="Active"
                                />
                            </div>
                            <div className="form-check form-check-inline mt-2">
                                <SolRadioInput
                                    {...register("firmware_status")}
                                    id="inactive"
                                    value={"inactive"}
                                    handleChange={(event) => handleFirmwareStatusSelectionChange(event)}
                                    checked={firmwareStatus === "inactive"}
                                    htmlFor="inactive"
                                    labelName="Inactive"
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="warning" size={'sm'} type="submit"
                                disabled={false}>
                            Apply filter
                        </Button>
                        <Button variant="outline-dark" size={'sm'} disabled={false}
                                onClick={resetFilter} className={'ml-3'}>
                            Reset filter
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    }

    const resetFilter = () => {
        setValue('firmware_type', '')
        setFirmwareStatus("")
    }

    const filterTheList = (data) => {
        console.log(data.firmware_type.value)
        console.log(firmwareStatus)
    }
    // ----- End: Filtering process related ---- //

    return (
        <ContentWrapper isLoading={false} pageTitle={"Firmware List"} showBackButton={false} showCardHeader={false}>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><strong>Firmwares</strong></h1>
                        </Toolbar.Title>
                        <Toolbar.ToolbarContainer>
                            <Toolbar.ToolbarContainer.Items>
                                <IconButton onClick={filterToggle} classes={classes}>
                                    <FilterListIcon color="disabled" fontSize="large"/>
                                </IconButton>
                                <IconButton classes={classes} onClick={exportData}
                                            disabled={false}>
                                    {props.something? <Spinner animation={"grow"} variant={'warning'}
                                                                                    size={"lg"}/> :
                                        <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>}
                                </IconButton>
                            </Toolbar.ToolbarContainer.Items>
                        </Toolbar.ToolbarContainer>
                    </Toolbar>
                    <hr/>
                </div>
            </div>
            {/* Filter form */}
            {showFilterForm? <>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        {filterForm()}
                        <hr/>
                    </div>
                </div>
            </>:''}
            {/* Table */}
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <DataTableContainer>
                        <DataTable
                            language={language}
                            noDataAvailableMessageInEnglish={'No firmwares available'}
                            columns={firmwareListColumns}
                            data={firmwares}
                            showToolbar={false}
                            asyncPagination={false}
                            pagination={true}
                            pageSize={5}
                            actionColumnIndex={-1}
                            overrideCustomActions={true}
                            actions={[
                                {
                                    position: "row",
                                    action: (rowData) => ({
                                        icon: () => {
                                            return <Button variant={'warning'}
                                                           size={'sm'}
                                                           style={{ whiteSpace: 'nowrap' }} disabled={rowData.status === 'inactive'}>
                                                <AddIcon fontSize={"small"}/> Assign
                                            </Button>
                                        },
                                        onClick: (e, row) => {goToAssignDonglePage(row)},
                                    })
                                },
                                {
                                    position: "row",
                                    action: (rowData) => ({
                                        icon: () => {
                                            return <Button variant={'warning'}
                                                           size={'sm'}
                                                           style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                <EditIcon fontSize={"small"}/> Edit
                                            </Button>
                                        },
                                        onClick: (e, row) => {goToEditFirmwarePage(row)},
                                    })
                                }
                            ]}
                        />
                    </DataTableContainer>
                </div>
            </div>
            {renderCompatibilityDetailsModal()}
        </ContentWrapper>
    );
};

NewFirmwareList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        // isLoading: state.contractManagementReducer.isLoading
    }
}

export default connect(mapStateToProps, actions)(NewFirmwareList);
