import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../smartDongles/views/components";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import DataTable from "../../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Badge, Button, Spinner} from "react-bootstrap";
import {useSubheader} from "../../../../../_metronic/layout";
import {useLocation} from 'react-router';
import moment from "moment";
import {Form} from "react-bootstrap";
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import Box from '@mui/material/Box';
import {showNotifications} from "../../../../utils/notification";
import {otaReducer} from "../../reducer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {DataTableContainerForDongleList} from "../../utils";
import Skeleton from "@mui/material/Skeleton";
import {makeStyles} from "@material-ui/styles";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {GenericModal} from "../../../../components";
import {DataTableContainer} from "../../../rentLog/utils";
import Typography from "@mui/material/Typography";
import {useMediaQuery} from "react-responsive";
import Stack from "@mui/material/Stack";


const FwCompatibilities = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    // Note that, in other places of the project we set 'max-width' to '1224px' but here we have set it to '1023px'
    // Because, while testing at the Chrome browser for Nest Hub (1024 * 600) settings (mobile view), "Compatible dongles view" modal filter form design gets broken
    // Setting max-width to 1023 ensure that Nest Hub users or users with view port of more than 1023 width, gets represented with the desktop filter form design
    // -- Noor Reza, 15th October, 2024 11:57 am
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
    const subHeader = useSubheader()
    const location = useLocation()

    // Page related
    const [mainFirmware, setMainFirmware] = useState('')
    const [compatibleHwVersions, setCompatibleHwVersions] = useState([])

    // Tab styling and selection related
    const useStyles = makeStyles((theme) => ({
        tab: {
            '&:hover': {
                color: '#F18D00'
            },
            '&:focus': {
                color: '#F18D00'
            },
            '&.Mui-selected': {
                color: '#F18D00'
            },
            textTransform: 'none',
            ...theme.typography.h6
        },
        firstTab: {
            marginRight: isTabletOrMobile? theme.spacing(2):'20%', // Add margin-right for the first tab only
        },
        indicator: {
            backgroundColor: '#F18D00'
        }
    }));
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0)
    const TabPanel = ({...props}) => {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}
                 {...other}>
                {value === index && (
                    <Box p={2}>{children}</Box>
                )}
            </div>
        );
    }
    // TODO: Following labels will be populated during dongle list collection
    const [activeDongleListLabel, setActiveDongleListLabel] = useState('Active SOLdongles (2)')
    const [allowedDongleListLabel, setAllowedDongleListLabel] = useState('Allowed SOLdongles (2)')

    // Dongle list related
    const [showCurrentFwColumn, setShowCurrentFwColumn] = useState(false)
    const [activeDongles, setActiveDongles] = useState([{
        "serial_number": "44000118",
        "firmware_version": "1.4.6",
        "old_firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "created_at": "2024-10-10T14:12:37.639757+00:00",
        "updated_by": "Tushar Imran"
    }, {
        "serial_number": "44000230",
        "firmware_version": "1.4.6",
        "old_firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "created_at": "2024-10-10T14:17:37.639757+00:00",
        "updated_by": "Nazmul Hossain Pranto"
    }])
    const [allowedDongles, setAllowedDongles] = useState([{
        "serial_number": "44001111",
        "firmware_version": "1.3.8",
        "hardware_version": "2.0",
        "created_at": "2024-09-28T15:12:37.639757+00:00",
        "updated_by": "Sakib Al Hasan"
    }, {
        "serial_number": "44002233",
        "firmware_version": "1.3.7",
        "hardware_version": "1.9.2",
        "created_at": "2015-09-12T10:10:37.639757+00:00",
        "updated_by": "Mashrafe Bin Mortaza"
    }])

    const dongleListColumns = [
        {
            field: 'serial_number',
            title: 'SOLdongle',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.serial_number}</strong></h6>
                    <span className={"text-nowrap"}>HW v{rowData.hardware_version}</span>
                </>
            }
        },
        {
            field: showCurrentFwColumn? 'firmware_version':'old_firmware_version',
            title: showCurrentFwColumn? 'Current F.W.':'Old F.W.',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{showCurrentFwColumn? rowData.firmware_version:rowData.old_firmware_version}</span>
                </>
            }
        },
        {
            field: 'created_at',
            title: 'Updated at',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{moment(rowData.created_at).format("MMM DD, YYYY hh:mm a")}</span>
                </>
            }
        },
        {
            field: 'updated_by',
            title: 'Updated by',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{rowData.updated_by}</span>
                </>
            }
        }
    ]


    const {
        RETURN_TO_INITIAL
    } = props

    useEffect(()=>{
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        if (location.fwData) {
            let fw = location.fwData.version
            setMainFirmware(fw)
            sessionStorage.setItem("fwVersion", fw)

            let hwVersionList = location.fwData.hardware_version_list.map((hw) => {
                return {
                    label: hw,
                    value: hw
                }
            })
            setCompatibleHwVersions(hwVersionList)
            sessionStorage.setItem("hwVersions", JSON.stringify(hwVersionList))
        } else {
            if (sessionStorage.getItem("fwVersion")) {
                setMainFirmware(sessionStorage.getItem("fwVersion"))
            }
            if (sessionStorage.getItem("hwVersions")) {
                setCompatibleHwVersions(JSON.parse(sessionStorage.getItem("hwVersions")))
            }
        }
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    // ----- Begin: Filtering process related ---- //
    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(collectFilteredDongles)}>
                {isTabletOrMobile? <>
                    {/* Mobile view */}
                    <div className={"row g-3"}>
                        <div className={"col-md-4"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>SOLdongle ID</Form.Label>
                                </div>
                                <SolInput
                                    name={'dongle_sl'}
                                    type={'number'}
                                    min={'1'}
                                    step={'1'}
                                    placeholder={'Type SOLdongle ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className={"row g-3"}>
                        <div className={"col-md-12"}>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={false}>
                                Apply filter
                            </Button>
                            <Button variant="outline-dark" size={'sm'} disabled={false}
                                    onClick={() => console.log("here")} className={'ml-3'}>
                                Reset filter
                            </Button>
                        </div>
                    </div>
                </>:<>
                    {/* Desktop view */}
                    <Stack direction="row" spacing={2} alignItems="center">
                        <div>
                            <Form.Group>
                                <div>
                                    <Form.Label>SOLdongle ID</Form.Label>
                                </div>
                                <SolInput
                                    name={'dongle_sl'}
                                    type={'number'}
                                    min={'1'}
                                    step={'1'}
                                    placeholder={'Type SOLdongle ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={false}>
                                Apply filter
                            </Button>
                        </div>
                        <div>
                            <Button variant="outline-dark" size={'sm'} disabled={false}
                                    onClick={() => console.log("here")}>
                                Reset filter
                            </Button>
                        </div>
                    </Stack>
                </>}
            </Form>
        </>
    }

    const collectFilteredDongles = (data) => {
        console.log(data.dongle_sl)
    }

    // ----- End: Filtering process related ---- //

    // ----- Begin: Tab and SOLdongle list related ---- //
    const handleTabSelection = (event, newValue) => {
        setTabValue(newValue)
        if (newValue === 1) {
            setShowCurrentFwColumn(true)
        } else {
            setShowCurrentFwColumn(false)
        }
    }
    // ----- End: Tab and SOLdongle list related ---- //

    return (
        <>
            {filterForm()}
            {/* Table */}
            <div className={isTabletOrMobile? "row mt-4":"row"}>
                <div className={"col-md-12"}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabSelection}
                        aria-label="operational logs"
                        classes={{ indicator: classes.indicator}}
                        centered={!isTabletOrMobile}
                    >
                        <Tab label={activeDongleListLabel} className={`${classes.tab} ${classes.firstTab}`}/>
                        <Tab label={allowedDongleListLabel} className={classes.tab}/>
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <DataTableContainerForDongleList>
                                    <DataTable
                                        language={language}
                                        noDataAvailableMessageInEnglish={'No dongles available'}
                                        columns={dongleListColumns}
                                        data={activeDongles}
                                        showToolbar={false}
                                        asyncPagination={false}
                                        pagination={true}
                                        pageSize={5}
                                    />
                                </DataTableContainerForDongleList>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <DataTableContainerForDongleList>
                                    <DataTable
                                        language={language}
                                        noDataAvailableMessageInEnglish={'No dongles available'}
                                        columns={dongleListColumns}
                                        data={allowedDongles}
                                        showToolbar={false}
                                        asyncPagination={false}
                                        pagination={true}
                                        pageSize={5}
                                    />
                                </DataTableContainerForDongleList>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </div>
        </>
    );
};

FwCompatibilities.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, actions)(FwCompatibilities);